<div class="header">
  <img src="../assets/img/BMWGroupNext_BMW_MINI_RR_GB_Grey-Colour_RGB.svg" alt="BMW Group">
  <span class="vertical-divider"></span>
  <a class="awp-title text-regular text-neutral" routerLink="/">Aftersales Workplace</a>

  @if (dealerDataService.outlets) {
    <ds-form-field class="outlet-dropdown mx-1x">
      <!-- Virtual scroll of ds-select sometimes hides the last entry if dropdown options wrap. -->
      <!-- That's why we prevent wrapping and use custom templates with tooltips instead.-->
      <ds-select
        [valueLabelTemplate]="outletEntryValueTemplate"
        [itemLabelTemplate]="outletEntryOptionTemplate"
        [allowOptionsToWrap]=false
        [(value)]="selectedOutletId"
        (optionSelected)="outletDropdownChanged()"
        [options]="outletDropdownOptions"
        [isFilterable]="true"
      ></ds-select>
    </ds-form-field>

    <!-- template for the selected value in the dropdown -->
    <ng-template #outletEntryValueTemplate let-value>
      <span [title]="value.label">{{ value.label }}</span>
    </ng-template>

    <!-- template for the dropdown options -->
    <ng-template #outletEntryOptionTemplate let-option>
      <span [title]="option.data.label">{{ option.data.label }}</span>
    </ng-template>
  }

  @if (visibilityService.outletConfigurationVisible) {
    <button ds-button variant="outline" icon="group_settings" class="mx-1x"
            (click)="openConfiguration()">{{ 'user.configuration' | translate }}
    </button>
  }

  <span class="vertical-divider"></span>

  <app-user-dropdown [viewState]="viewState"></app-user-dropdown>

  <span class="vertical-divider"></span>

  <button ds-button variant="ghost-muted" icon="imprint" class="mx-1x" [ds-modal-trigger-for]="imprintModal"></button>
  <ds-modal #imprintModal>
    <ds-box-header>{{ 'common.termsOfUseHeader' | translate }}</ds-box-header>
    <ds-box-content class="text-regular">
      <p>{{ 'common.termsOfUseText' | translate }}</p>
      <br>
      <p>{{ 'common.imprintText' | translate }}</p>
    </ds-box-content>
    <ds-box-footer>
      <button ds-button #dsModalDismissElement>OK</button>
    </ds-box-footer>
  </ds-modal>
</div>

@if (visibilityService.outletConfigurationVisible && viewState.configurationDialogVisible) {
  <app-configuration-overlay [viewState]="viewState"></app-configuration-overlay>
}
