import {Component, ElementRef, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EtkVehicleInfo, VehicleInfo, VehicleSearchResult} from '@service-and-repairs/dms-api';
import {ColDef, GridApi, GridReadyEvent} from 'ag-grid-community';
import {AnalyticsService} from '../../core/analytics/services/analytics.service';
import {DmsSearchColumnDefinitions} from './dms-search-column-definitions';
import {DmsVehicle} from './dms-vehicle';
import {DmsService} from './dms.service';

@Component({
    selector: 'app-dms-license-plate-search',
    templateUrl: './dms-license-plate-search.component.html',
    styleUrls: ['./dms-search.scss'],
    standalone: false
})
export class DmsLicensePlateSearchComponent {
  @Output()
  selectedVehicle = new EventEmitter<DmsVehicle>();

  MAX_SEARCH_RESULTS = 10;

  licensePlateSearchInput: string;

  dcomLicensePlateSearchResult: VehicleSearchResult;
  etkDmsLicensePlateSearchResult: EtkVehicleInfo[];

  errorMessage: string;
  showErrorIndicator = false;
  showLoadingIndicator = false;
  showNoResultInfo = false;

  defaultColDef: ColDef;
  dcomSearchResultColumns: ColDef<VehicleInfo>[];
  etkSearchResultColumns: ColDef<EtkVehicleInfo>[];
  gridApi: GridApi;

  constructor(private dmsService: DmsService,
              private translate: TranslateService,
              private elementRef: ElementRef,
              private analyticsService: AnalyticsService) {
    this.loadColumnDefinitions();
    translate.onLangChange.subscribe(this.loadColumnDefinitions.bind(this));
  }

  searchLicensePlate(): void {
    this.clearPreviousSearchResults();
    if (this.licensePlateSearchInput?.trim().length > 0) {
      // If both DCOM and ETK DMS are enabled, use DCOM.
      if (this.dmsService.dmsSettings.dcom.isLicensePlateSearchEnabled) {
        this.searchVehiclesByLicensePlateViaDcom();
        this.analyticsService.postDmsRequestEvent('dcom-license-plate-search');
      } else if (this.dmsService.dmsSettings.etk.isEnabled) {
        this.searchVehiclesByLicensePlateViaEtkDms();
        this.analyticsService.postDmsRequestEvent('etk-license-plate-search');
      }
    }
  }

  selectVehicleFromDcom(dcomVehicle: VehicleInfo): void {
    this.selectedVehicle.emit(DmsVehicle.fromDcomVehicle(dcomVehicle));
  }

  selectVehicleFromEtkDms(etkVehicle: EtkVehicleInfo): void {
    this.selectedVehicle.emit(DmsVehicle.fromEtkVehicle(etkVehicle, this.licensePlateSearchInput));
  }

  resizeColumns(): void {
    if (this.elementVisible()) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }

  private elementVisible() {
    // element is visible if it has an offset parent
    return !!this.elementRef.nativeElement.offsetParent;
  }

  private clearPreviousSearchResults(): void {
    this.dcomLicensePlateSearchResult = undefined;
    this.etkDmsLicensePlateSearchResult = undefined;
    this.errorMessage = undefined;
    this.showErrorIndicator = false;
    this.showNoResultInfo = false;
  }

  private loadColumnDefinitions(): void {
    this.defaultColDef = DmsSearchColumnDefinitions.getDefaultSearchResultColumnDefinition();
    this.dcomSearchResultColumns = DmsSearchColumnDefinitions.getDcomLicensePlateSearchResultColumns(this.translate);
    this.etkSearchResultColumns = DmsSearchColumnDefinitions.getEtkSearchResultColumns(this.translate);
  }

  private searchVehiclesByLicensePlateViaDcom(): void {
    this.showLoadingIndicator = true;
    this.dmsService
      .searchVehiclesByLicensePlateViaDcom(this.licensePlateSearchInput, this.MAX_SEARCH_RESULTS)
      .then(result => this.handleDcomLicensePlateSearchResult(result))
      .catch(error => this.handleLicensePlateSearchError(error))
      .finally(() => this.showLoadingIndicator = false);
  }

  private handleDcomLicensePlateSearchResult(result: VehicleSearchResult): void {
    if (result.vehicles.length === 0) {
      this.showNoResultInfo = true;
    } else {
      this.dcomLicensePlateSearchResult = result;
    }
  }

  private searchVehiclesByLicensePlateViaEtkDms(): void {
    this.showLoadingIndicator = true;
    this.dmsService
      .searchVehiclesByLicensePlateViaEtkDms(this.licensePlateSearchInput)
      .then(result => this.handleEtkDmsLicensePlateSearchResult(result))
      .catch(error => this.handleLicensePlateSearchError(error))
      .finally(() => this.showLoadingIndicator = false);
  }

  private handleEtkDmsLicensePlateSearchResult(result: EtkVehicleInfo) {
    // contains only one vehicle
    if (result) {
      this.etkDmsLicensePlateSearchResult = [result];
    }
  }

  private handleLicensePlateSearchError(error: Error) {
    this.errorMessage = error?.message;
    this.showErrorIndicator = true;
  }
}
