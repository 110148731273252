import {Component} from '@angular/core';
import {Data, NavigationEnd, Router} from '@angular/router';
import {environment} from '@environments/environment';
import {IServerApi} from '@service-and-repairs/awpintegrationlib';
import {filter} from 'rxjs/operators';
import {AuthenticationTokenBearerService} from '../auth/authentication-token-bearer.service';
import {AnalyticsService} from '../core/analytics/services/analytics.service';
import {ServiceCaseHolder} from '../core/service-case/models/service-case-holder';
import {ServiceCaseManager} from '../core/service-case/models/service-case-manager';
import {UserInfo} from '../core/user/interfaces/user-info';
import {UserData} from '../core/user/models/user-data';
import {LanguageService} from '../core/user/services/language.service';
import {UserService} from '../core/user/services/user.service';
import {AwpClientLibRepublishService} from '../services/awp-client-lib-republish.service';
import {AwpClientLibService} from '../services/awp-client-lib.service';
import {NotificationsService} from '../services/notifications.service';
import {ThinClientService} from '../services/thin-client.service';
import {Util} from '../util/util';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  awpLib: IServerApi;
  showCaseBar = false;
  appVisible = false;
  loading = false;
  serviceCaseActive = false;
  shortlistViewState = {visible: false};

  // in Korea, a legal disclaimer has to be displayed on every login
  koreaDisclaimerState = {visible: false};

  constructor(awpClientLibService: AwpClientLibService,
              private router: Router,
              private thinClientService: ThinClientService,
              private serviceCaseHolder: ServiceCaseHolder,
              private serviceCaseManager: ServiceCaseManager,
              private languageService: LanguageService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService,
              private userService: UserService,
              private analyticsService: AnalyticsService,
              private notificationsService: NotificationsService,
              private awpClientLibRepublishService: AwpClientLibRepublishService) {
    // Subscribe to republish requests from AWP client lib
    this.awpClientLibRepublishService.init();
    this.awpLib = awpClientLibService.serverApi;

    this.subscribeToNotifications();

    this.serviceCaseManager.caseLoadingStarted.subscribe(() => {
      this.loading = true;
    });
    this.serviceCaseManager.caseLoadingFinished.subscribe(() => {
      this.loading = false;
    });
    this.serviceCaseHolder.activeServiceCaseChanged.subscribe(() => {
      this.serviceCaseActive = !!this.serviceCaseHolder.getActiveCase();
      this.shortlistViewState.visible = this.shortlistViewState.visible && this.serviceCaseActive;
    });

    // User is preloaded by OidcFlow.js, show app once request has finished
    this.waitForUserToLoad()
      .then((userInfo: UserInfo) => this.onUserLoaded(UserService.transformUserInfoToUserData(userInfo)))
      .catch((e) => console.error(Util.logPrefix('Webapp', 'App') + `Error while waiting for user to load: ${e}.`));

    if (environment.deploymentEnvironment === 'DEV_TEST') {
      this.loadUserlane();
    }
  }

  onRouteActivate(activatedRouteData: Data): void {
    // Run asynchronously to prevent updates between Angular change detection runs
    setTimeout(() => {
      this.showCaseBar = !activatedRouteData.appConfiguration.hideCaseBar;
    }, 0);
  }

  onRouteDeactivate(): void {
    this.showCaseBar = false;
  }

  private waitForUserToLoad(): Promise<UserInfo> {
    return new Promise<UserInfo>(resolve => {
      // Return user data, if already available
      if (window['bmwNamespace']?.userData) {
        resolve(window['bmwNamespace'].userData);
      }
      // Subscribe for user data to become available, return once available
      else {
        window.addEventListener('userDataLoaded', () => {
          resolve(window['bmwNamespace'].userData);
        });
      }
    });
  }

  private onUserLoaded(userData: UserData): void {
    // Don't show webapp if user is in B2D but has no business relationships
    if (userData.getContext() === 'B2D' && !userData.hasValidBusinessRelationship()) {
      return;
    }

    this.authenticationTokenBearerService.fetchAndStoreCsslToken(userData)
      .then(() => {
        console.info(Util.logPrefix('Webapp', 'App') + 'User data and CSSL token available. Loading app.');
      })
      .catch(() => {
        console.warn(Util.logPrefix('Webapp', 'App') + 'User data available but CSSL token is missing. Loading app.');
      })
      .finally(() => {
        this.userService.publishUser(userData);
        this.showDisclaimerForKoreanUsers();
        this.analyticsService.postUserLoggedInEvent();
        this.languageService.use(userData.getLocale()).then(this.setAppVisible.bind(this));
      });
  }

  private showDisclaimerForKoreanUsers(): void {
    this.koreaDisclaimerState.visible = this.userService.userSubject.getValue()?.getB2XCountryCode() === 'KR';
  }

  private setAppVisible(): void {
    this.appVisible = true;
    document.getElementById('splash-screen')?.remove();
    this.thinClientService.initialize(this.userService.userSubject.getValue()?.getContext() === 'B2D');
  }

  private subscribeToNotifications(): void {
    this.awpLib.subscribeToInfoNotifications((msg) => this.notificationsService.showInfoNotification(msg));
    this.awpLib.subscribeToSuccessNotifications((msg) => this.notificationsService.showSuccessNotification(msg));
    this.awpLib.subscribeToWarningNotifications((msg) => this.notificationsService.showWarningNotification(msg));
    this.awpLib.subscribeToErrorNotifications((msg) => this.notificationsService.showErrorNotification(msg));
  }

  private loadUserlane(): void {
    // load
    window['UserlaneCommandObject'] = 'Userlane';
    window['Userlane'] = window['Userlane'] || function() {
      (window['Userlane'].q = window['Userlane'].q || []).push(arguments);
    };
    let userLaneScriptElement: HTMLScriptElement = document.createElement('script');
    let firstScriptElement: HTMLScriptElement = document.getElementsByTagName('script')[0];
    userLaneScriptElement.async = true;
    userLaneScriptElement.src = 'https://cdn.userlane.com/userlane.js';
    firstScriptElement.parentNode.insertBefore(userLaneScriptElement, firstScriptElement);

    // only show in parts search
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('partssearch')) {
          window['Userlane']('init', 'z7g327nx4e');
        } else {
          window['Userlane']('hide');
        }
      });
  };
}
