import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OidcFlow} from '@assets/OidcFlow';
import {environment} from '@environments/environment';
import {OidcConfigProvider} from '@oidc/OidcConfigProvider';
import {
  ActorId,
  BusinessPartner,
  IPartsCatalogSettings,
  OutletPeer,
  PartsCatalogSettings
} from '@service-and-repairs/awpintegrationlib';
import {BehaviorSubject, Observable} from 'rxjs';
import {CsslToken} from '../../../auth/cssl/cssl-token';
import {OidcToken} from '../../../auth/oidc/oidc-token';
import {IIframeAppConfiguration} from '../../../interfaces/IIframeAppConfiguration';
import {IWebComponentAppConfiguration} from '../../../interfaces/IWebComponentAppConfiguration';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {UserInfo} from '../interfaces/user-info';
import {
  MileageUnit,
  PressureUnit,
  TemperatureUnit,
  TreadDepthUnit,
  UserSettings,
  VolumeUnit
} from '../interfaces/user-settings';
import {UserData} from '../models/user-data';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userSubject = new BehaviorSubject<UserData>(null);

  constructor(private awpClientLibService: AwpClientLibService,
              private httpClient: HttpClient) {
  }

  static transformUserInfoToUserData(userInfo: UserInfo): UserData {
    const settings = userInfo.userSettings;

    // User data
    const user = new UserData();
    user.setId(userInfo.id);
    user.setName(userInfo.firstName + ' ' + userInfo.lastName);
    user.setLoginName(userInfo.loginName);
    user.setSettingsId(settings.id);
    user.setContext(userInfo.businessContext);
    user.setLocale(settings.systemLanguage);
    user.setEmail(userInfo.email);
    user.setDefaultApp(settings.defaultApp);
    user.setMileageUnit(settings.mileageUnit || 'km');
    user.setVolumeUnit(settings.volumeUnit || 'l');
    user.setTemperatureUnit(settings.temperatureUnit || 'C');
    user.setNewCaseAfterVehicleIdentification(settings.newCaseAfterVehicleIdentification);
    user.setRoles(userInfo.roles);
    user.setOutletPeers(userInfo.outletPeers);
    user.setCaseTabShowCustomer(settings.caseTabShowCustomer);
    user.setCaseTabShowModel(settings.caseTabShowModel);
    user.setCaseTabShowTitle(settings.caseTabShowTitle);
    user.setCaseTabShowVin(settings.caseTabShowVin);

    // Catalogue settings
    const partsCatalogSettings: IPartsCatalogSettings = new PartsCatalogSettings();
    partsCatalogSettings.setCatalogScope('CLASSIC'); // TODO from Mathis: Remove/replace this default value.
    partsCatalogSettings.setLocalContent('ECE'); // TODO from Mathis: Remove/replace this default value.
    user.setPartsCatalogSettings(partsCatalogSettings);

    // Contextual business information
    if (user.getContext() !== 'B2E') {
      user.setDmsUsername(settings.dmsUserId);
      user.setDmsPassword(settings.dmsUserPassword);
      user.setDmsServiceAdvisorId(settings.dmsServiceAdvisorId);
      user.setBusinessPartners(userInfo.businessPartners.map(p => BusinessPartner.constructFromPlainObject(p)));
      user.setSelectedBusiness(settings.selectedBusinessPartnerId, settings.selectedVehicleType);
      user.setKeyReaderId(settings.keyReaderId);
    } else {
      user.setSelectedCountry(settings.selectedCountry);
      user.setSelectedBrands(settings.selectedBrands || []);
    }

    return user;
  }

  private static endSession(oidcConfig: any, idToken: string): void {
    window.location.href =
      `https://${oidcConfig.wenEnvironment}/auth/oauth2/realms/root/realms/` + oidcConfig.realm + `/connect/endSession?`
      + `id_token_hint=${idToken}`
      + `&post_logout_redirect_uri=${window.location.protocol}//${window.location.hostname}`;
  }

  static getUserSettingsFromUserData(user: UserData): UserSettings {
    const settings: UserSettings = {
      id: user.getSettingsId(),
      businessContext: user.getContext(),
      caseTabShowCustomer: user.getCaseTabShowCustomer(),
      caseTabShowModel: user.getCaseTabShowModel(),
      caseTabShowTitle: user.getCaseTabShowTitle(),
      caseTabShowVin: user.getCaseTabShowVin(),
      defaultApp: user.getDefaultApp(),
      dmsUserPassword: user.getDmsPassword(),
      dmsUserId: user.getDmsUsername(),
      dmsServiceAdvisorId: user.getDmsServiceAdvisorId(),
      keyReaderId: user.getKeyReaderId(),
      mileageUnit: <MileageUnit>user.getMileageUnit(),
      newCaseAfterVehicleIdentification: user.getNewCaseAfterVehicleIdentification(),
      selectedBrands: undefined,
      selectedBusinessPartnerId: undefined,
      selectedCountry: undefined,
      selectedVehicleType: undefined,
      systemLanguage: user.getLocale(),
      temperatureUnit: <TemperatureUnit>user.getTemperatureUnit(),
      userId: user.getId(),
      volumeUnit: <VolumeUnit>user.getVolumeUnit(),
      pressureUnit: <PressureUnit>user.getPressureUnit(),
      treadDepthUnit: <TreadDepthUnit>user.getTreadDepthUnit()
    };

    if (user.getContext() === 'B2E') {
      settings.selectedBrands = user.getSelectedBrands();
      settings.selectedCountry = user.getSelectedCountry();
    } else {
      settings.dmsUserPassword = user.getDmsPassword();
      settings.dmsUserId = user.getDmsUsername();
      settings.dmsServiceAdvisorId = user.getDmsServiceAdvisorId();
      settings.selectedBusinessPartnerId = user.getBusinessPartner().getBusinessPartnerId();
      settings.selectedVehicleType = user.getSelectedVehicleType();
    }

    return settings;
  }

  getOutletPeers(): Promise<OutletPeer[]> {
    return new Promise<OutletPeer[]>((resolve) => {
      this.httpClient.get('/api/user/v1').subscribe(
        {
          next: (response: any) => resolve(response.data.outletPeers),
          error: () => {
            console.log('[WebApp][UserService] Could not get outlet peers');
            resolve([]);
          }
        }
      );
    });
  }

  setUserSettings(updatedUserSettings: UserSettings): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient.put('/api/user/settings/v1', {data: updatedUserSettings})
        .subscribe({
          next: () => resolve(),
          error: reject
        });
    });
  }

  deletePersonalData(): Observable<any> {
    this.deleteColumnsSettings();
    return this.deleteUserSettings();
  }

  private deleteColumnsSettings(): void {
    this.httpClient.delete('/api/service-case-details/v2/columns-settings').subscribe();
  }

  private deleteUserSettings(): Observable<any> {
    return this.httpClient.delete('/api/user/settings/v1');
  }

  publishUser(user: UserData, destination?: ActorId): void {
    this.userSubject.next(user);
    this.awpClientLibService.serverApi.publishActiveUser(user, destination);
  }

  appVisibleForUser(routingPath: string): boolean {
    const user: UserData = this.userSubject.getValue();
    if (user) {
      for (const category of environment.categories) {
        const appConfig: IIframeAppConfiguration | IWebComponentAppConfiguration = category.apps.find(app => (app.routingPath ===
          routingPath));
        if (appConfig) {
          const requiredRoles: string[] = user.getContext() === 'B2E' ? appConfig.b2eRoles : appConfig.b2dRoles;
          // return true if there are no roles required, check user roles otherwise
          return !requiredRoles
            || requiredRoles.length === 0
            || requiredRoles.some(requiredRole => user.getRoles().includes(requiredRole));
        }
      }
    }
    return false;
  }

  getVisibleApps(): (IIframeAppConfiguration | IWebComponentAppConfiguration)[] {
    const apps: (IIframeAppConfiguration | IWebComponentAppConfiguration)[] = [];
    for (const category of environment.categories) {
      category.apps.forEach((app) => {
        if (this.appVisibleForUser(app.routingPath) && this.appVisibleInContext(app)) {
          apps.push(app);
        }
      });
    }
    return apps;
  }

  private appVisibleInContext(app: IIframeAppConfiguration | IWebComponentAppConfiguration): boolean {
    const user = this.userSubject.getValue();
    const context = user ? user.getContext() : '';
    const urls = (app as IIframeAppConfiguration).iFrameUrls || (app as IWebComponentAppConfiguration).webComponentUrls;
    switch (context) {
      case 'B2E':
        return !!urls.b2e;
      case 'B2D':
        return !!urls.b2d;
      case 'B2D_INTERNAL':
        return !!urls.b2dIntranet;
      default:
        return false;
    }
  }

  logOut(): void {
    console.log('[Webapp] [User] Log out user.');
    // Remove authentication tokens from local storage and end WEN session
    const config = (new OidcConfigProvider()).get(window.location.hostname);
    const oidcFlow = new OidcFlow(config);
    if (oidcFlow.oidcIsSupported()) {
      const businessContext = this.userSubject.getValue().getContext();

      const oidcToken = OidcToken.readFromStorage(businessContext);
      const csslToken = CsslToken.readFromStorage(businessContext);

      if (csslToken?.jwt) {
        csslToken.deleteFromStorage();
      }

      if (oidcToken?.id_token) {
        oidcToken.deleteFromStorage();
        sessionStorage.removeItem('cavors-session-id');
        UserService.endSession(config, oidcToken.id_token);
      }
    }
  }

  /**
   * Returns true if user is either etk_b2d_admin or awp_b2d_dealeradmin.
   */
  userIsDealerAdmin(): boolean {
    const user = this.userSubject.getValue();
    return !!user
      && !user.isB2E()
      && (user.getRoles().includes('etk_b2d_admin') || user.getRoles().includes('awp_b2d_dealeradmin'));
  }
}
