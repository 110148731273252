import {Component, HostBinding, Input, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MileageDataSource} from '@service-and-repairs/awpintegrationlib';
import {ServiceCase} from '../../../../core/service-case/models/service-case';
import {ServiceCaseHolder} from '../../../../core/service-case/models/service-case-holder';
import {ServiceCaseManager} from '../../../../core/service-case/models/service-case-manager';
import {UserData} from '../../../../core/user/models/user-data';
import {ServiceCaseReloadOverlayComponent} from '../service-case-reload-overlay/service-case-reload-overlay.component';

@Component({
    selector: 'app-service-case-tab',
    templateUrl: './service-case-tab.component.html',
    styleUrls: ['./service-case-tab.component.scss'],
    standalone: false
})
export class ServiceCaseTabComponent {

  @Input()
  serviceCase: ServiceCase;

  @Input()
  user: UserData;

  @Input()
  @HostBinding('class.tooltip')
  isTooltip: boolean;

  @Input()
  @HostBinding('class.large')
  isLarge: boolean;

  @ViewChild('reloadOverlay', {static: false})
  private caseReloadOverlay: ServiceCaseReloadOverlayComponent;

  constructor(private serviceCaseHolder: ServiceCaseHolder,
              private serviceCaseManager: ServiceCaseManager,
              private translate: TranslateService) {
  }

  onReloadClick(): void {
    if (this.serviceCase.getVinLong()) {
      this.serviceCaseManager.reloadServiceCaseById(this.serviceCase.getExternalId(), false)
        .finally(() => {
          // do nothing
        });
    } else {
      this.caseReloadOverlay.show(this.serviceCase);
    }
  }

  onCloseClick(): void {
    this.serviceCaseHolder.closeServiceCaseById(this.serviceCase.getExternalId(), 'webapp');
  }

  getWarningsTooltip(): string {
    return this.serviceCase.getWarnings()
      .filter(warning => !warning.isStop)
      .map(warning => {
        if (warning.message?.toLowerCase().includes('alpina')) {
          // only show first line of Alpina warning in tooltip
          return warning.message?.split('<br>')[0];
        } else {
          return warning.message;
        }
      })
      // create new line for each warning
      .join('\n');
  }

  getSalesStopTooltip(): string {
    return this.serviceCase.getWarnings()
      .filter(warning => warning.isStop)
      .map(warning => warning.message)
      // usually there is only one sales stop, use line break anyway
      .join('\n');
  }

  showTabImage(): boolean {
    return !!this.serviceCase.getSmallCosyImageUrl()
      && !this.serviceCase.vehicleDataMissing()
      && !this.isTooltip;
  }

  getMileageSourceText(source: MileageDataSource): string {
    switch (source) {
      case MileageDataSource.KEY_READER:
      case MileageDataSource.REMOTE_KEY_READ:
      case MileageDataSource.TELESERVICES:
        return this.translate.instant('vehicle.mileageSourceKey');
      case MileageDataSource.VEHICLE_HISTORY:
        return this.translate.instant('vehicle.mileageSourceFBM');
      case MileageDataSource.OVER_THE_AIR:
        return this.translate.instant('vehicle.mileageSourceVS');
      case MileageDataSource.MANUAL:
        return this.translate.instant('vehicle.mileageSourceManual');
      default:
        return this.translate.instant('vehicle.mileageSourceOther');
    }
  }
}
