import {Component} from '@angular/core';
import {ServiceCaseHolder} from '../../../../core/service-case/models/service-case-holder';
import {UserData} from '../../../../core/user/models/user-data';
import {LanguageService} from '../../../../core/user/services/language.service';
import {UserService} from '../../../../core/user/services/user.service';
import {Locales} from '../../../../locales';

@Component({
    selector: 'app-customer-language-popup',
    templateUrl: './customer-language-popup.component.html',
    standalone: false
})
export class CustomerLanguagePopupComponent {
  popupVisible: boolean = false;
  showSaveError: boolean = false;
  showLanguageWarning: boolean = false;
  showLoadingIndicator: boolean = false;

  user: UserData;
  serviceCaseLanguage: string;

  private currentCustomerLanguage: string;

  constructor(private userService: UserService,
              private languageService: LanguageService,
              serviceCaseHolder: ServiceCaseHolder) {
    userService.userSubject.subscribe((user: UserData) => this.user = user);

    serviceCaseHolder.activeServiceCaseChanged.subscribe(() => {
      if (this.currentCustomerLanguage !== serviceCaseHolder.getActiveCase()?.getCustomerLanguage()) {
        this.currentCustomerLanguage = serviceCaseHolder.getActiveCase()?.getCustomerLanguage();
        this.onNewCaseSelected(serviceCaseHolder);
      }
    });
  }

  confirm(): void {
    this.showSaveError = false;
    this.showLoadingIndicator = true;

    this.user.setLocale(this.serviceCaseLanguage);

    this.userService.setUserSettings(UserService.getUserSettingsFromUserData(this.user))
      .then(() => this.languageService.use(this.serviceCaseLanguage).then(() => this.close()))
      .catch(() => {
        this.showLoadingIndicator = false;
        this.showSaveError = true;
      })
      .finally(() => this.userService.userSubject.next(this.user));
  }

  close(): void {
    this.showSaveError = false;
    this.popupVisible = false;
    this.showLoadingIndicator = false;
  }

  private onNewCaseSelected(serviceCaseHolder: ServiceCaseHolder): void {
    this.serviceCaseLanguage = serviceCaseHolder.getActiveCase()?.getCustomerLanguage();
    this.popupVisible = !!this.serviceCaseLanguage && this.serviceCaseLanguage !== this.user?.getLocale();

    this.showLanguageWarning = !Locales.getLocaleIds().includes(this.serviceCaseLanguage);
  }
}
