import {Component, Input} from '@angular/core';
import {SelectListOption} from '@bmw-ds/components';
import {TranslateService} from '@ngx-translate/core';
import {Brand, IServerApi} from '@service-and-repairs/awpintegrationlib';
import {AnalyticsService} from '../../core/analytics/services/analytics.service';
import {UserSettings} from '../../core/user/interfaces/user-settings';
import {UserData} from '../../core/user/models/user-data';
import {LanguageService} from '../../core/user/services/language.service';
import {UserService} from '../../core/user/services/user.service';
import {IIframeAppConfiguration} from '../../interfaces/IIframeAppConfiguration';
import {IWebComponentAppConfiguration} from '../../interfaces/IWebComponentAppConfiguration';

import {Locales} from '../../locales';
import {AwpClientLibService} from '../../services/awp-client-lib.service';
import {countries} from './countries';

@Component({
    selector: 'app-user-settings-overlay',
    templateUrl: './user-settings-overlay.component.html',
    styleUrls: ['./user-settings-overlay.component.scss'],
    standalone: false
})
export class UserSettingsOverlayComponent {

  @Input()
  state: {settingsDialogVisible: boolean};

  userSettings: UserSettings;

  previousDefaultApp: string;
  defaultAppOptions: SelectListOption[] = [];
  systemLanguageOptions: SelectListOption[] = [];
  countryOptions: SelectListOption[] = [];
  brandOptions: SelectListOption[] = [];

  awpLib: IServerApi;
  backendCallPending = false;
  errorMessage: string;

  constructor(awpClientLibService: AwpClientLibService,
              public userService: UserService,
              private analyticsService: AnalyticsService,
              private languageService: LanguageService,
              private translate: TranslateService) {
    this.awpLib = awpClientLibService.serverApi;

    this.setDefaultAppOptions();
    this.translate.onLangChange.subscribe(this.setDefaultAppOptions.bind(this));

    Locales.getLocaleIds().forEach((locale: string) => {
      this.systemLanguageOptions.push({
        label: this.getLabelForUserLocale(locale),
        id: locale
      });
    });

    countries.forEach((country: string) => {
      this.countryOptions.push({
        label: this.translate.instant('country.' + country),
        id: country
      });
    });

    this.brandOptions = [
      {
        label: 'BMW',
        id: Brand.BMW
      },
      {
        label: 'BMW i',
        id: Brand.BMW_i
      },
      {
        label: 'MINI',
        id: Brand.MINI
      },
      {
        label: 'Rolls-Royce',
        id: Brand.RR
      },
      {
        label: 'BMW Motorrad',
        id: Brand.MOT
      },
      {
        label: 'Zinoro',
        id: Brand.ZINORO
      }
    ];

    this.userService.userSubject
      .subscribe((userData: UserData) => {
        this.userSettings = UserService.getUserSettingsFromUserData(userData);
        this.previousDefaultApp = this.userSettings.defaultApp;
      });
  }

  static clearSettingsFromLocalStorage(): void {
    const keys = Object.keys(localStorage);
    for (const key of keys) {
      if (!key.toLowerCase().includes('token')) {
        localStorage.removeItem(key);
      }
    }
  }

  private postDefaultAppChangedEvent(): void {
    if (this.userSettings.defaultApp !== this.previousDefaultApp) {
      this.analyticsService.postUserSettingsChangedEvent('defaultApp', this.userSettings.defaultApp);
    }
  }

  saveSettings(): void {
    this.errorMessage = undefined;

    this.backendCallPending = true;
    this.userService.setUserSettings(this.userSettings)
      .then((): void => {
        this.closeDropdown();
        this.languageService.use(this.userSettings.systemLanguage).then((): void => {
          this.awpLib.notifySuccess(this.translate.instant('user.notificationSaveSuccess'));
        });
      })
      .catch((): void => {
        this.languageService.use(this.userSettings.systemLanguage).then((): void => {
          this.errorMessage = this.translate.instant('user.notificationSaveError');
        });
      })
      .finally((): void => {
        this.backendCallPending = false;
        this.postDefaultAppChangedEvent();
        this.updateAndPublishUser();
      });
  }

  deleteSettings(): void {
    this.errorMessage = undefined;
    this.backendCallPending = true;
    this.userService.deletePersonalData().subscribe({
      next: () => {
        UserSettingsOverlayComponent.clearSettingsFromLocalStorage();
        this.userService.logOut();
      },
      error: () => {
        this.backendCallPending = false;
        this.errorMessage = this.translate.instant('user.notificationDeleteError');
      }
    });
  }

  closeDropdown(): void {
    this.state.settingsDialogVisible = false;
  }

  getLabelForUserLocale(locale: string): string {
    switch (locale) {
      // Chinese and Taiwanese should not appear as languages in China, show Simplified/Traditional Chinese instead
      case 'zh-CN':
        return 'zh-SI';
      case 'zh-TW':
        return 'zh-TR';
      default:
        return locale;
    }
  }

  hasBrandsError(): boolean {
    // in B2E, brands are required
    return this.userService.userSubject.getValue().getContext() === 'B2E'
      && (!this.userSettings.selectedBrands || this.userSettings.selectedBrands.length === 0);
  }

  private updateAndPublishUser(): void {
    const user: UserData = this.userService.userSubject.getValue();
    user.setUserSettings(this.userSettings);
    this.userService.publishUser(user);
  }

  private setDefaultAppOptions(): void {
    this.defaultAppOptions = [];
    this.userService.getVisibleApps().forEach((app: IIframeAppConfiguration | IWebComponentAppConfiguration) => {
      this.defaultAppOptions.push({
        label: this.translate.instant(app.titleResource),
        id: app.titleResource
      });
    });
  }
}
