import {Component, ElementRef, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  AppointmentInfo,
  AppointmentSearchResult,
  EtkVehicleInfo,
  OrderInfo,
  OrderSearchResult
} from '@service-and-repairs/dms-api';
import {ColDef, GridApi, GridReadyEvent} from 'ag-grid-community';
import {AnalyticsService} from '../../core/analytics/services/analytics.service';
import {DmsSearchColumnDefinitions} from './dms-search-column-definitions';
import {DmsVehicle} from './dms-vehicle';
import {DmsService} from './dms.service';

@Component({
    selector: 'app-dms-order-search',
    templateUrl: './dms-order-search.component.html',
    styleUrls: ['./dms-search.scss'],
    standalone: false
})
export class DmsOrderSearchComponent {
  @Output()
  selectedVehicle: EventEmitter<DmsVehicle> = new EventEmitter<DmsVehicle>();

  MAX_SEARCH_RESULTS: number = 10;

  orderSearchInput: string;

  dcomOrderSearchResult: (OrderInfo | AppointmentInfo)[];
  etkDmsOrderSearchResult: EtkVehicleInfo[];

  errorMessage: string;
  showErrorIndicator: boolean = false;
  showLoadingIndicator: boolean = false;
  showNoResultInfo: boolean = false;
  showTooManyResultInfo: boolean = false;

  defaultColDef: ColDef;
  dcomSearchResultColumns: ColDef<OrderInfo | AppointmentInfo>[];
  etkSearchResultColumns: ColDef<EtkVehicleInfo>[];
  gridApi: GridApi;

  constructor(private dmsService: DmsService,
              private translate: TranslateService,
              private elementRef: ElementRef,
              private analyticsService: AnalyticsService) {
    this.loadColumnDefinitions();
    translate.onLangChange.subscribe(this.loadColumnDefinitions.bind(this));
  }

  searchOrder(): void {
    this.clearPreviousSearchResults();
    if (this.orderSearchInput) {
      if (this.dmsService.dmsSettings.dcom.url?.trim().length > 0) {
        this.searchOrderViaDcom();
        this.analyticsService.postDmsRequestEvent('dcom-order-search');
      } else if (this.dmsService.dmsSettings.etk.isEnabled) {
        this.searchOrderViaEtkDms();
        this.analyticsService.postDmsRequestEvent('etk-order-search');
      }
    }
  }

  selectVehicleFromDcom(dcomInfo: OrderInfo | AppointmentInfo): void {
    if (isOrderInfo(dcomInfo)) {
      this.selectedVehicle.emit(DmsVehicle.fromDcomOrder(dcomInfo));
    } else {
      this.selectedVehicle.emit(DmsVehicle.fromDcomAppointment(dcomInfo));
    }
  }

  selectVehicleFromEtkDms(etkVehicle: EtkVehicleInfo): void {
    this.selectedVehicle.emit(DmsVehicle.fromEtkVehicle(etkVehicle));
  }

  resizeColumns(): void {
    if (this.elementVisible()) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }

  private elementVisible() {
    // element is visible if it has an offset parent
    return !!this.elementRef.nativeElement.offsetParent;
  }

  private clearPreviousSearchResults(): void {
    this.dcomOrderSearchResult = undefined;
    this.etkDmsOrderSearchResult = undefined;
    this.errorMessage = undefined;
    this.showErrorIndicator = false;
    this.showNoResultInfo = false;
  }

  private loadColumnDefinitions(): void {
    this.defaultColDef = DmsSearchColumnDefinitions.getDefaultSearchResultColumnDefinition();
    this.dcomSearchResultColumns = DmsSearchColumnDefinitions.getDcomOrderSearchResultColumns(this.translate);
    this.etkSearchResultColumns = DmsSearchColumnDefinitions.getEtkSearchResultColumns(this.translate);
  }

  private searchOrderViaDcom(): void {
    this.showLoadingIndicator = true;
    Promise.all([
      this.dmsService.searchOrderViaDcom(this.orderSearchInput, null, this.MAX_SEARCH_RESULTS),
      this.dmsService.searchAppointmentViaDcom(this.orderSearchInput, null, this.MAX_SEARCH_RESULTS)
    ])
      .then(([orderResult, appointmentResult]: [OrderSearchResult, AppointmentSearchResult]) => this.handleDcomOrderAppointmentSearchResults(
        orderResult,
        appointmentResult
      ))
      .catch((err: Error) => this.handleDcomOrderSearchError(err))
      .finally(() => this.showLoadingIndicator = false);
  }

  private searchOrderViaEtkDms(): void {
    this.showLoadingIndicator = true;
    this.dmsService
      .searchOrderViaEtkDms(this.orderSearchInput)
      .then((result: EtkVehicleInfo) => this.handleEtkDmsOrderSearchResult(result))
      .catch((err: Error) => this.handleEtkDmsOrderSearchError(err))
      .finally(() => this.showLoadingIndicator = false);
  }

  private handleDcomOrderAppointmentSearchResults(orderResults: OrderSearchResult,
                                                  appointmentResults: AppointmentSearchResult): void {
    if (orderResults?.orders.length === 0 && appointmentResults?.appointments.length === 0) {
      this.showNoResultInfo = true;
    } else {
      this.showTooManyResultInfo = orderResults?.maxResultSize >
        this.MAX_SEARCH_RESULTS ||
        appointmentResults?.maxResultSize >
        this.MAX_SEARCH_RESULTS;
      this.dcomOrderSearchResult = [].concat(orderResults.orders).concat(appointmentResults.appointments);
    }
  }

  private handleDcomOrderSearchError(err: Error): void {
    this.showErrorIndicator = true;
    this.errorMessage = err?.message;
  }

  private handleEtkDmsOrderSearchError(err: Error): void {
    if (err?.message?.includes('1748')) {
      // 'no results' is returned as an error with error code 1748
      this.showNoResultInfo = true;
    } else {
      this.showErrorIndicator = true;
      this.errorMessage = err?.message;
    }
  }

  private handleEtkDmsOrderSearchResult(result: EtkVehicleInfo): void {
    // contains only one vehicle
    if (result) {
      this.etkDmsOrderSearchResult = [result];
    }
  }
}

function isOrderInfo(dcomInfo: OrderInfo | AppointmentInfo): dcomInfo is OrderInfo {
  return (dcomInfo as OrderInfo).serviceOrderNumber !== undefined;
}
