import {Component, ElementRef, HostBinding, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {Case, ICase} from '@service-and-repairs/awpintegrationlib';
import {AuthInfo} from '../../../../auth/auth-info';
import {AuthInfoFactory} from '../../../../auth/auth-info-factory';
import {AuthenticationTokenBearerService} from '../../../../auth/authentication-token-bearer.service';
import {LeadsConfiguration} from '../../../../core/configuration/interfaces/leads-configuration';
import {ConfigurationLoader} from '../../../../core/configuration/services/configuration.loader';
import {ServiceCaseHolder} from '../../../../core/service-case/models/service-case-holder';
import {ServiceCaseUpdater} from '../../../../core/service-case/models/service-case-updater';
import {UserData} from '../../../../core/user/models/user-data';
import {UserService} from '../../../../core/user/services/user.service';
import {Util} from '../../../../util/util';

export const LOCAL_STORAGE_KEY_FOR_SHORTLIST_PINNED_STATE = 'shortlistPinned';

@Component({
    selector: 'app-shortlist',
    templateUrl: './shortlist.component.html',
    styleUrls: ['./shortlist.component.scss'],
    standalone: false
})
export class ShortlistComponent implements OnInit {
  @Input()
  shortlistViewState = {visible: false};

  @HostBinding('class.pinned')
  isPinned: boolean = true;

  @ViewChildren('shortlistWebComponent')
  shortlistElementRefs: QueryList<ElementRef>;

  showErrorIndicator: boolean = false;

  leadsConfiguration: LeadsConfiguration;

  user: UserData;
  authInfo: AuthInfo;
  locale: string;

  constructor(userService: UserService,
              public configurationLoader: ConfigurationLoader,
              public serviceCaseHolder: ServiceCaseHolder,
              private readonly serviceCaseUpdater: ServiceCaseUpdater,
              private readonly router: Router,
              private readonly authenticationTokenBearerService: AuthenticationTokenBearerService) {
    userService.userSubject.subscribe((user: UserData): void => {
      this.user = user;
      this.locale = user.getLocale();
    });
    this.onAuthTokenChanged();
    this.authenticationTokenBearerService.oidcTokenSubject.subscribe(this.onAuthTokenChanged.bind(this));
    this.authenticationTokenBearerService.csslTokenSubject.subscribe(this.onAuthTokenChanged.bind(this));
    this.configurationLoader.configChanged
      .subscribe(config => this.leadsConfiguration = config.outletRelatedConfig?.leadsDealerConfiguration);
    this.serviceCaseHolder.activeServiceCaseChanged.subscribe(() => this.onActiveCaseChanged());
  }

  ngOnInit(): void {
    this.loadServiceCaseFrontendSource();
    this.restorePinnedStateFromLocalStorage();
  }

  togglePinnedState(): void {
    this.isPinned = !this.isPinned;
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_SHORTLIST_PINNED_STATE, JSON.stringify(this.isPinned));
  }

  openServiceCaseDetails(): void {
    this.router.navigateByUrl('casedetails').then((): void => this.close());
  }

  close(): void {
    this.shortlistViewState.visible = false;
  }

  isHidden(serviceCase: ICase): boolean {
    return !serviceCase || (this.serviceCaseHolder.getActiveCase()?.getExternalId() !== serviceCase.getExternalId());
  }

  showFlatRateUnitAmount(): boolean {
    return this.configurationLoader.showFlatRateUnitValues();
  }

  private loadServiceCaseFrontendSource(): void {
    Util.attachScript('/ui/service-case/main.js')
      .catch((): void => {
        this.showErrorIndicator = true;
      });
  }

  private restorePinnedStateFromLocalStorage(): void {
    try {
      this.isPinned = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_FOR_SHORTLIST_PINNED_STATE));
    } catch (e) {
      // isPinned is true by default
    }
  }

  private onAuthTokenChanged(): void {
    if (this.user) {
      this.authInfo = AuthInfoFactory.makeAuthInfo(this.user);
    } else {
      this.authInfo = undefined;
    }
  }

  private onActiveCaseChanged(): void {
    // recreate active case on respective shortlist instance to trigger change detection
    const activeCaseId: string = this.serviceCaseHolder.getActiveCase()?.getExternalId();
    if (activeCaseId) {
      this.shortlistElementRefs.forEach((elementRef: ElementRef): void => {
        const shortlistCase: ICase = elementRef.nativeElement['activeCase'];
        if (shortlistCase?.getExternalId() === activeCaseId) {
          elementRef.nativeElement['activeCase'] = Case.constructFromPlainObject(this.serviceCaseHolder.getActiveCase());
        }
      });
    }
  }
}
