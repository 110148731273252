import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ServiceCase} from '../../../../core/service-case/models/service-case';
import {UserService} from '../../../../core/user/services/user.service';
import {OrderAndAppointmentSearchResult} from "../../../dms-search/order-and-appointment-search-result";

@Component({
    selector: 'app-dms-order-for-vin-selection',
    templateUrl: './dms-order-for-vin-selection.component.html',
    styleUrls: ['./shared-styles.scss'],
    standalone: false
})
export class DmsOrderForVinSelectionComponent {
  @Output()
  newCaseWithOrder = new EventEmitter<ServiceCase>();

  @Input()
  orderList: OrderAndAppointmentSearchResult[];

  visible = false;
  locale: string;

  constructor(private userService: UserService) {
    this.locale = this.userService.userSubject.getValue()?.getLocale();
    this.userService.userSubject.subscribe(user => {
      this.locale = user.getLocale();
    })
  }

  orderSelected(dcomOrderOrAppointment: OrderAndAppointmentSearchResult): void {
    this.visible = false;
    const newServiceCaseWithDmsData = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    if (dcomOrderOrAppointment.vehicle.vin?.length === 7) {
      newServiceCaseWithDmsData.setVinShort(dcomOrderOrAppointment.vehicle.vin);
    } else {
      newServiceCaseWithDmsData.setVinLong(dcomOrderOrAppointment.vehicle.vin);
    }
    newServiceCaseWithDmsData.setLicensePlate(dcomOrderOrAppointment.vehicle.numberPlate);
    newServiceCaseWithDmsData.setDmsOrderNumber(dcomOrderOrAppointment.dmsId);
    newServiceCaseWithDmsData.setDmsCustomerNumber(dcomOrderOrAppointment.customer?.customerId?.dmsId);
    newServiceCaseWithDmsData.setIsDmsPreOrder(dcomOrderOrAppointment.isDmsPreOrder)
    this.newCaseWithOrder.emit(newServiceCaseWithDmsData);
  }

}
