import {Component, ElementRef, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Customer, CustomerWithVehicles, Vehicle} from '@service-and-repairs/calm-leads-customer-lib-api';
import {ColDef, GridApi, GridReadyEvent} from 'ag-grid-community';
import {AnalyticsService} from '../../../core/analytics/services/analytics.service';
import {CustomerLoader} from '../../../core/customer/services/customer.loader';
import {DmsVehicle} from '../dms-vehicle';
import {CustomerSearchColumnDefinitions} from './customer-search-column-definitions';

@Component({
    selector: 'app-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss'],
    standalone: false
})
export class CustomerSearchComponent {

  @Output()
  selectedVehicle = new EventEmitter<DmsVehicle>();

  firstName: string;
  lastName: string;

  showLoadingIndicator = false;
  searchResult: CustomerWithVehicles[];
  errorMessage: string;

  vehicleColumnDefinitions: ColDef<Vehicle>[];
  defaultColumnDefinition: ColDef<Vehicle>;
  private gridApi: GridApi<Vehicle>;

  constructor(private elementRef: ElementRef,
              private translate: TranslateService,
              private analyticsService: AnalyticsService,
              private customerLoader: CustomerLoader) {
    this.defaultColumnDefinition = CustomerSearchColumnDefinitions.getDefaultVehicleColumnDefinition();
    this.vehicleColumnDefinitions = CustomerSearchColumnDefinitions.getVehicleColumnDefinitions(this.translate);
  }

  onGridReady(event: GridReadyEvent<Vehicle, Vehicle>): void {
    this.gridApi = event.api;
  }

  resizeColumns(): void {
    if (this.elementVisible()) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  searchCustomer(): void {
    this.searchResult = undefined;
    this.errorMessage = undefined;
    this.showLoadingIndicator = true;
    this.customerLoader.getCustomersByName(this.firstName, this.lastName)
      .then(customers => this.searchResult = customers)
      .catch((error: Error) => this.errorMessage = error.message)
      .finally(() => this.showLoadingIndicator = false);
    this.analyticsService.postDmsRequestEvent('customer-search');
  }

  selectVehicle(vehicle: Vehicle, customer: Customer): void {
    this.selectedVehicle.emit({
      customerId: customer.idCollection.dmsId,
      customerName: customer.nameCollection?.name,
      vin: vehicle.vin,
      licensePlate: vehicle.numberPlate
    });
  }

  private elementVisible(): boolean {
    // element is visible if it has an offset parent
    return !!this.elementRef.nativeElement.offsetParent;
  }
}
