import {Component, Input} from '@angular/core';
import {UserService} from "../../core/user/services/user.service";

@Component({
    selector: 'app-korea-disclaimer',
    templateUrl: 'korea-disclaimer.component.html',
    standalone: false
})
export class KoreaDisclaimerComponent {

  @Input()
  koreaDisclaimerState: { visible: boolean };

  constructor(private userService: UserService) {
  }

  accept(): void {
    this.koreaDisclaimerState.visible = false;
  }

  reject(): void {
    this.userService.logOut();
  }
}
