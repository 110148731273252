import {Component} from '@angular/core';
import {ServiceCase} from '../../../../core/service-case/models/service-case';
import {ServiceCaseManager} from '../../../../core/service-case/models/service-case-manager';

@Component({
    selector: 'app-service-case-reload-overlay',
    templateUrl: './service-case-reload-overlay.component.html',
    styleUrls: ['./service-case-reload-overlay.component.scss'],
    standalone: false
})
export class ServiceCaseReloadOverlayComponent {

  visible = false;
  showVinMismatchError = false;

  vin17Input: string;

  serviceCase: ServiceCase;

  constructor(private serviceCaseManager: ServiceCaseManager) {
  }

  show(serviceCase: ServiceCase): void {
    this.visible = true;
    this.showVinMismatchError = false;
    this.serviceCase = serviceCase;
    this.vin17Input = serviceCase.getVinShort();
  }

  hide(): void {
    this.visible = false;
  }

  confirm(): void {
    if (!this.inputMatchesServiceCaseVin()) {
      this.showVinMismatchError = true;
    } else if (this.vin17Input?.length === 17) {
      this.showVinMismatchError = false;
      this.reloadCase();
    }
  }

  reloadCase(): void {
    if (this.vin17Input?.length === 17 && this.inputMatchesServiceCaseVin()) {
      this.setVin17InServiceCase(this.vin17Input);
    }

    // We do not need to save the service case here. We just reopen the service case, which will reload the vehicle
    // and save the service case afterward.
    this.serviceCaseManager.reloadServiceCaseById(this.serviceCase.getExternalId(), false)
      .finally(() => {
        // do nothing
      });

    this.hide();
  }

  private setVin17InServiceCase(vin17: string) {
    this.serviceCase.setVinLong(vin17);
    if (this.serviceCase.getVehicle()) {
      this.serviceCase.getVehicle()['vin'] = this.serviceCase.getVinLong();
    }
  }

  private inputMatchesServiceCaseVin(): boolean {
    return this.vin17Input.endsWith(this.serviceCase.getVinShort());
  }
}
