import {Location} from '@angular/common';
import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ServiceCaseHolder} from '../../core/service-case/models/service-case-holder';
import {VehicleLoader} from '../../core/vehicle/services/vehicle.loader';
import {IWebComponentAppConfiguration} from '../../interfaces/IWebComponentAppConfiguration';
import {WebComponentService} from './web-component.service';

@Component({
    selector: 'app-web-component',
    templateUrl: './web-component.component.html',
    styleUrls: ['./web-component.component.scss'],
    standalone: false
})
/**
 * This component is created by Angular routing if a web component app is selected. It will only exist while a web
 * component is shown, the actual web components exist separately and will not be destroyed once created. This
 * component manages visibility and error/loading indicators. It uses the WebComponentService to actually manage the
 * web components.
 */
export class WebComponentComponent implements OnInit, OnDestroy {

  @HostBinding('class.visible')
  showErrorOrLoadingIndicator: boolean;

  appConfiguration: IWebComponentAppConfiguration;
  additionalAttributes: object;

  loadingIndicatorVisible = false;
  errorIndicatorVisible = false;
  serviceCaseRequiredErrorVisible = false;
  vehicleRequiredErrorVisible = false;
  vin17RequiredErrorVisible = false;

  private selectedCaseSubscription: Subscription;
  private caseClosedSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              public serviceCaseHolder: ServiceCaseHolder,
              private location: Location,
              private webComponentService: WebComponentService) {
    this.additionalAttributes = this.location.getState()?.['queryParams'] || {};
  }

  ngOnInit(): void {
    this.appConfiguration = this.route.snapshot.data['appConfiguration'];
    this.initWebComponent();

    this.selectedCaseSubscription = this.serviceCaseHolder.serviceCaseSelected.subscribe(() => {
      this.updateAppVisibility();
    });

    this.caseClosedSubscription = this.serviceCaseHolder.serviceCaseClosed.subscribe((serviceCaseId: string) => {
      this.webComponentService.deleteWebComponentsOfCase(serviceCaseId);
    });
  }

  ngOnDestroy(): void {
    this.selectedCaseSubscription?.unsubscribe();
    this.caseClosedSubscription?.unsubscribe();
    this.webComponentService.hideAllApps();
  }

  /**
   * This will load the web component source(s) and add the component to the DOM. It will not be shown yet.
   */
  private initWebComponent(): void {
    this.showLoadingIndicator();
    this.webComponentService.initApp(
      this.appConfiguration,
      this.route.snapshot.data['webComponentUrls'],
      this.route.snapshot.data['getEventListeners'],
      this.route.snapshot.data['getAttributes']
    )
      .catch(this.showErrorIndicator.bind(this))
      .finally(this.hideLoadingIndicator.bind(this));
  }

  /**
   * Set the current app visible if there are no errors and web component has loaded, or hide all apps otherwise.
   */
  private updateAppVisibility(): void {
    this.updateErrorAndLoadingIndicators();
    if (this.showErrorOrLoadingIndicator) {
      this.webComponentService.hideAllApps();
    } else {
      this.webComponentService.showApp(
        this.serviceCaseHolder.getActiveCase()?.getExternalId(),
        this.appConfiguration,
        this.additionalAttributes
      );
    }
  }

  /**
   * Show an error that web component source could not be loaded.
   */
  private showErrorIndicator(): void {
    this.errorIndicatorVisible = true;
    this.updateAppVisibility();
  }

  /**
   * Hide the web component source loading indicator.
   */
  private hideLoadingIndicator(): void {
    this.loadingIndicatorVisible = false;
    this.updateAppVisibility();
  }

  /**
   * Show the web component source loading indicator.
   */
  private showLoadingIndicator(): void {
    this.loadingIndicatorVisible = true;
    this.updateAppVisibility();
  }

  /**
   * Check if any errors or the loading indicator should be shown. If not, this.showErrorOrLoadingIndicator is set to
   * false.
   */
  private updateErrorAndLoadingIndicators(): void {
    const activeCase = this.serviceCaseHolder.getActiveCase();
    const serviceCaseAbsent = !activeCase;
    const vehicleAbsent = !VehicleLoader.isValidVin7(activeCase?.getVinShort())
      && !VehicleLoader.isValidTypeCode(activeCase?.getTypeCode());
    const vin17Absent = !VehicleLoader.isValidVin17(activeCase?.getVinLong());
    this.serviceCaseRequiredErrorVisible = !!this.appConfiguration.serviceCaseRequired && serviceCaseAbsent;
    this.vehicleRequiredErrorVisible = !!this.appConfiguration.vehicleRequired && vehicleAbsent;
    this.vin17RequiredErrorVisible = !!this.appConfiguration.vinLongRequired && vin17Absent;

    this.showErrorOrLoadingIndicator = this.serviceCaseRequiredErrorVisible || this.vin17RequiredErrorVisible
      || this.vehicleRequiredErrorVisible || this.errorIndicatorVisible || this.loadingIndicatorVisible;
  }
}
