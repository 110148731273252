import {Component, input, InputSignal, output, OutputEmitterRef} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {DsToggleButtonModule} from "@bmw-ds/components";
import {ServiceCaseHolder} from "../../../../core/service-case/models/service-case-holder";

@Component({
    selector: 'app-shopping-cart-button',
    imports: [
        AsyncPipe,
        DsToggleButtonModule
    ],
    templateUrl: './shopping-cart-button.component.html'
})
export class ShoppingCartButtonComponent {
  active: InputSignal<boolean> = input();
  click: OutputEmitterRef<void> = output<void>();

  constructor(public serviceCaseHolder: ServiceCaseHolder) {
  }

  onClick(): void {
    this.click.emit();
  }
}
