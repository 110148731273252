import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {
  Brand,
  IBusinessPartner,
  IBusinessRelationship,
  IEquipmentOption,
  IVehicle,
  TransmissionType,
  VehicleType
} from '@service-and-repairs/awpintegrationlib';
import {AuthInfoFactory} from '../auth/auth-info-factory';
import {AuthenticationTokenBearerService} from '../auth/authentication-token-bearer.service';
import {OidcToken} from '../auth/oidc/oidc-token';
import {DmsService} from '../components/dms-search/dms.service';
import {ConfigurationLoader} from '../core/configuration/services/configuration.loader';
import {KeyData} from '../core/keydata/interfaces/key-data';
import {KeyDataLoader} from '../core/keydata/services/key-data.loader';
import {ServiceCase} from '../core/service-case/models/service-case';
import {ServiceCaseHolder} from '../core/service-case/models/service-case-holder';
import {UserData} from '../core/user/models/user-data';
import {UserService} from '../core/user/services/user.service';
import {EnvironmentUrlKey} from '../interfaces/IEnvironment';
import {IIframeAppConfiguration} from '../interfaces/IIframeAppConfiguration';
import {IWebComponentAppConfiguration} from '../interfaces/IWebComponentAppConfiguration';
import {AirParamService} from './air-param.service';

/**
 * Service to provide attributes (inputs) for web components.
 */
@Injectable({
  providedIn: 'root'
})
export class WebComponentAttributeService {
  user: UserData;

  constructor(private readonly dmsService: DmsService,
              private readonly configurationLoader: ConfigurationLoader,
              private readonly serviceCaseHolder: ServiceCaseHolder,
              private readonly userService: UserService,
              private readonly translate: TranslateService,
              private readonly keyDataLoader: KeyDataLoader,
              private readonly authenticationTokenBearerService: AuthenticationTokenBearerService) {
    userService.userSubject.subscribe((user: UserData) => this.user = user);
  }

  // TODO: If possible remove and use method from UserData instead
  private static getBusinessNumber(businessRelationships: IBusinessRelationship[], brand?: Brand | string): string {
    if (!businessRelationships || businessRelationships.length == 0) {
      console.warn('Using empty BuNo because the list of business relationships is null or empty.');
      return '';
    }
    if (brand) {
      const relationshipWithMatchingBrand: IBusinessRelationship[] = businessRelationships
        .filter((r: IBusinessRelationship) => r.getBrands()?.some(((b: Brand): boolean => b === brand)));
      if (relationshipWithMatchingBrand.length > 0) {
        return relationshipWithMatchingBrand[0].getBusinessNumber();
      }
    }
    return businessRelationships[0].getBusinessNumber();
  }

  getAttributesForApp(app: IWebComponentAppConfiguration): object {
    const cavorsSessionId: string = sessionStorage.getItem('cavors-session-id');
    const attributes: object = {
      'environment': environment.deploymentEnvironment,
      'auth-info': AuthInfoFactory.makeAuthInfo(this.user),
      'headers': this.getHeaders(),
      'cavors-session-id': cavorsSessionId || '',
      'analytics-id': cavorsSessionId || '' // legacy attribute name
    };
    return Object.assign(attributes, this.getAppSpecificAttributes(app));
  }

  /**
   * Any parameters for an iframe url or a web component have to be set here.
   *
   * @param app The app shown in the iframe / web component.
   */
  private getAppSpecificAttributes(app: IIframeAppConfiguration | IWebComponentAppConfiguration): object {
    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    const brand: string = serviceCase?.getBrand();

    switch (app.routingPath) {
      case 'casedetails':
        return this.getServiceCaseDetailsParams();
      case 'caseoverview':
        return this.getServiceCaseOverviewParams();
      case 'ccrcarpark':
        return {
          'base-url': this.getUrlFromEnvironmentConfig('dealerCockpit')
        };
      case 'feedbackadmin':
        return this.getFeedbackAdminParams();
      case 'vehicledetails':
        return this.getVehicleDetailsParams();
      case 'diagnosticprotocols':
        return {
          vin: serviceCase.getVinLong()
        };
      case 'feedback':
        return this.getFeedbackParams();
      case 'partssearch':
        return this.getPartsSearchParams();
      case 'vehiclehistory':
        return this.getVehicleHistoryParams();
      case 'flatratesearch':
        return this.getFlatRateUnitsParams();
      case 'serviceleads': {
        const fleetParams: object = this.getLeadsParameters();
        Object.assign(fleetParams, {
          'outlet-members': this.user.getOutletPeers(),
          'specialist-groups': this.configurationLoader.getSpecialistGroups(),
          'cosy-base-url': this.getUrlFromEnvironmentConfig('cosy'),
          'los-base-url': this.getUrlFromEnvironmentConfig('los'),
          'scc-appointments-base-url': this.getUrlFromEnvironmentConfig('sccAppointmentsService')
        });
        return fleetParams;
      }
      case 'servicedemand': {
        const serviceDemandParams: object = this.getLeadsParameters(brand);
        Object.assign(serviceDemandParams, {
          'cosy-base-url': this.getUrlFromEnvironmentConfig('cosy'),
          'los-base-url': this.getUrlFromEnvironmentConfig('los'),
          'check-control-message-base-url': this.getUrlFromEnvironmentConfig('checkControlMessage'),
          'service-planning-base-url': this.getUrlFromEnvironmentConfig('servicePlanning'),
          'remote-key-read-base-url': this.getUrlFromEnvironmentConfig('remoteKeyRead'),
          'measure-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'navigation-map-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'esl-document-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'sel-document-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'sib-document-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'fault-codes-base-url': this.getUrlFromEnvironmentConfig('faultCodes'),
          'esa-base-url': this.getUrlFromEnvironmentConfig('esa'),
          'scc-appointments-base-url': this.getUrlFromEnvironmentConfig('sccAppointmentsService'),
          'service-case-id': this.serviceCaseHolder.getActiveCase()?.getExternalId(),
          'edit-mileage-base-url': this.getUrlFromEnvironmentConfig('editMileage'),
          'pressure-unit': this.user.getPressureUnit().toUpperCase()
        });
        this.applyTreadDepthUnitForLeads(serviceDemandParams, this.user.getTreadDepthUnit());
        return serviceDemandParams;
      }
      case 'marketconfiguration':
      case 'editingaudit':
        return {
          language: this.user.getLocale()
        };
      case 'servicepreparation':
        return this.getServicePreparationParameter(serviceCase);
      case 'customerdata':
        return {
          lang: this.user.getLocale(),
          baseUrl: this.getUrlFromEnvironmentConfig('customerData')
        };
      case 'dealerdashboard':
        return {
          language: this.user.getLocale()
        };
      default:
        return {};
    }
  }

  /**
   * Returns the headers a web component should add to its requests.
   */
  private getHeaders(): {[key: string]: string} {
    const headers: {[p: string]: string} = this.authenticationTokenBearerService.oidcTokenSubject
      .getValue()
      .getHeaders();
    headers['Auth-App-Id'] = 'awp';
    headers['Cavors-Session-Id'] = sessionStorage.getItem('cavors-session-id') || '';
    return headers;
  }

  private getUrlFromEnvironmentConfig(key: EnvironmentUrlKey): string {
    if (!environment.urls[key]) {
      console.warn('Route Factory Service: URL "' + key + '" not found in environment.ts.');
      return undefined;
    }
    const context: string = this.user?.getContext();
    switch (context) {
      case 'B2E':
        return environment.urls[key]?.b2e;
      case 'B2D':
        return environment.urls[key]?.b2d;
      case 'B2D_INTERNAL':
        return environment.urls[key]?.b2dIntranet;
      default:
        return undefined;
    }
  }

  private getServicePreparationParameter(serviceCase: ServiceCase): object {
    return {
      gCID: serviceCase?.getGcdmCustomerId(),
      brand: serviceCase?.getBrand(),
      vin: serviceCase?.getVinLong()
    };
  }

  private getFeedbackParams(): object {
    return {
      'app-list': this.userService.getVisibleApps()
        .map((a: IIframeAppConfiguration | IWebComponentAppConfiguration): {id: string, label: string} => ({
          id: a.titleResource,
          label: this.translate.instant(a.titleResource)
        }))
    };
  }

  private getFeedbackAdminParams(): object {
    return {
      'app-list': environment.categories
        .flatMap((category: any) => category.apps)
        .map((app: any): object => (
          {
            id: app.titleResource,
            label: this.translate.instant(app.titleResource)
          }
        )),
      'page-size': 50,
      language: this.user.getLocale()
    };
  }

  private getLeadsParameters(brand?: Brand | string): object {
    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    const businessPartner: IBusinessPartner = this.user?.getBusinessPartner();
    const leadsBusinessPartner: object = {
      'dpNo': businessPartner?.getDistributionPartnerNumber(),
      'businessRelationships': businessPartner?.getBusinessRelationships()
        .map((businessRelationship: IBusinessRelationship): object => ({
          'buno': businessRelationship.getBusinessNumber(),
          'brands': businessRelationship.getBrands()
        }))
    };
    const businessPartnerIds: string[] = this.user?.getBusinessPartners()
      .map((partner: IBusinessPartner) => partner.getBusinessPartnerId());
    const keyData: KeyData = this.keyDataLoader.getKeyDataForVin(this.serviceCaseHolder.getActiveCase()?.getVinLong());

    return {
      vin: serviceCase?.getVinLong(),
      'distribution-partner-number': businessPartner?.getDistributionPartnerNumber(),
      'outlet-number': businessPartner?.getOutletNumber(),
      'business-number': WebComponentAttributeService.getBusinessNumber(
        businessPartner?.getBusinessRelationships(),
        brand
      ),
      'distance-unit': this.user.getMileageUnit(),
      country: this.user.getB2XCountryCode(),
      language: this.user.getLocale(),
      brand: brand?.toString(),
      'mileage': serviceCase?.getMileageRecord(),
      'mileage-in-kilometers': serviceCase?.getCurrentMileageInKilometers(),
      'business-partner': leadsBusinessPartner,
      'business-partners': businessPartnerIds.join(','),
      'battery-state-of-charge-in-percent': keyData?.batteryStateOfChargeInPercent,
      'fuel-tank-level-in-liter': keyData?.fuelTankLevelInLiter,
      'mileage-in-kilometers-from-latest-key-read': keyData?.totalDistanceInKm,
      'data-read-date': keyData?.readInDate,
      'data-write-date': keyData?.writeDate,
      'data-read-provider': keyData?.keyOrigin,
      'user-full-name': this.user?.getName()
    };
  }

  private getServiceCaseDetailsParams(): object {
    const params: object = {};
    if (!this.user?.isB2E()) {
      Object.assign(params, {
        'specialist-groups': this.configurationLoader.getSpecialistGroups(),
        'dms-api': this.dmsService.dmsApi,
        'dms-settings': this.dmsService.dmsSettings
      });
    }
    Object.assign(params, {
      'parts-ordering': this.userService.appVisibleForUser('partsordering'),
      'show-flat-rate-unit-amount': this.configurationLoader.showFlatRateUnitValues(),
      'is-flat-rate-unit-amount-zero-allowed': this.configurationLoader.isFlatRateUnitAmountZeroAllowed(),
      'leads-dealer-config': this.configurationLoader.getLeadsDealerConfig(),
      'user-data': this.user,
      'active-case-data': this.serviceCaseHolder.getActiveCase()
    });
    return params;
  }

  private getServiceCaseOverviewParams(): object {
    return {
      'user-data': this.user,
      'specialist-groups': this.configurationLoader.getSpecialistGroups()
    };
  }

  private getVehicleDetailsParams(): object {
    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    const latestKeyData: KeyData = this.keyDataLoader.getKeyDataForVin(serviceCase.getVinLong());
    return {
      campaigns: serviceCase?.getCampaigns(),
      'service-contracts': serviceCase?.getServiceContracts(),
      'warranty-restrictions': serviceCase?.getWarrantyRestrictions(),
      'vehicle-warnings': serviceCase?.getWarnings(),
      'kasio-base-url': this.getUrlFromEnvironmentConfig('remoteKeyRead'),
      'user': this.user,
      // required in addition to user as Webapp determines a single business number
      'business-number': this.user?.getBusinessNumber(),
      'key-data-read-date': latestKeyData?.readInDate || '',
      'key-data-write-date': latestKeyData?.writeDate || '',
      'vehicle': serviceCase?.getVehicle()
    };
  }

  private getAirWebComponentParams(): object {
    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    const businessNumber: string = WebComponentAttributeService
      .getBusinessNumber(this.user?.getBusinessPartner()?.getBusinessRelationships(), serviceCase?.getBrand());
    const token: OidcToken = this.authenticationTokenBearerService.oidcTokenSubject.getValue();

    const params: object = {
      language: this.user?.getLocale(),
      'app-id': 'awp',
      vin: serviceCase?.getVinLong(),
      'auth-scheme': token.business_context.substring(0, 3),
      'auth-type': 'WEN_TOKEN',
      'access-token': token.access_token
    };
    if (this.user?.isB2E()) {
      Object.assign(
        params,
        {brands: AuthInfoFactory.awpBrandsToCsslBrands(this.user.getSelectedBrands()).join(',')}
      );
      Object.assign(params, {country: this.user?.getB2XCountryCode()});
    } else {
      Object.assign(params, {'dealer-code': businessNumber});
    }
    return params;
  }

  private getFlatRateUnitsParams(): object {
    const params: object = this.getAirWebComponentParams();
    Object.assign(params, {'flatrate-service-base-url': this.getUrlFromEnvironmentConfig('airServices')});
    Object.assign(params, {'show-packages-button': false});
    Object.assign(params, {'show-repair-instructions-button': false});
    Object.assign(params, {'show-defect-codes-button': false});
    Object.assign(params, {'show-flatrate-values': this.configurationLoader.showFlatRateUnitValues()});

    return params;
  }

  private getVehicleHistoryParams(): object {
    const params: object = this.getAirWebComponentParams();
    Object.assign(params, {'base-url': this.getUrlFromEnvironmentConfig('airServices')});
    Object.assign(
      params,
      {'mileage-unit': AirParamService.convertMileageUnitForAir(this.user?.getMileageUnit())}
    );
    if (this.isWarrantyHistoryDisabledInUserCountry()) {
      Object.assign(params, {'provide-warranty-history': false});
    }
    if (!this.user?.isB2E()) {
      Object.assign(
        params,
        {'allow-delete-service-history-entry': this.configurationLoader.isServiceHistoryDeletionAllowed()}
      );
    }

    return params;
  }

  private isWarrantyHistoryDisabledInUserCountry(): boolean {
    // General dealers in the asian markets China, Japan and Korea must not see the warranty history
    return ['KR', 'JP', 'CN'].includes(this.user.getB2XCountryCode());
  }

  private getPartsSearchParams(): object {
    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();

    return {
      language: this.user.getLocale(),
      vehicle: this.getVehicleParamsForPartsSearch(serviceCase?.getVehicle()),
      'base-url': this.getUrlFromEnvironmentConfig('carsServices'),
      'service-case-id': serviceCase?.getExternalId(),
      'session-id': sessionStorage.getItem('cavors-session-id')
    };
  }

  private getVehicleParamsForPartsSearch(vehicle: IVehicle): object {
    const params: object = {};

    if (vehicle?.getVin()) {
      Object.assign(params, {vin: vehicle.getVin()});
    }

    this.applyVehicleTypeForPartsSearch(params, vehicle?.getVehicleType());

    this.applyBrandForPartsSearch(params, vehicle?.getBrand());

    this.applyTransmissionTypeForPartsSearch(params, vehicle?.getTransmissionType());

    if (vehicle?.getTypeCode()) {
      Object.assign(params, {typeCode: vehicle.getTypeCode()});
    }
    if (vehicle?.getBaseTypeCode()) {
      Object.assign(params, {baseTypeCode: vehicle.getBaseTypeCode()});
    }

    if (vehicle?.getProductionDate()) {
      Object.assign(params, {productionDate: vehicle.getProductionDate()});
    }

    if (vehicle?.getUpholsteryCode()) {
      Object.assign(params, {upholsteryCode: vehicle.getUpholsteryCode()});
    }

    if (vehicle?.getPaintNumber()) {
      Object.assign(params, {paint: vehicle.getPaintNumber()});
    }

    if (vehicle?.getEquipmentOptions()) {
      const equipmentOptionCodes: string[] = vehicle.getEquipmentOptions()
        .map((option: IEquipmentOption) => option.getCode());
      Object.assign(params, {equipmentOptions: equipmentOptionCodes});
    }

    return params;
  }

  private applyTreadDepthUnitForLeads(params: object, unit: string): void {
    if (unit === 'mm') {
      Object.assign(params, {treadDepthUnit: 'MILLIMETER'});
    } else {
      Object.assign(params, {treadDepthUnit: unit.toUpperCase()});
    }
  }

  private applyVehicleTypeForPartsSearch(params: object, vehicleType: VehicleType): void {
    if (vehicleType === VehicleType.PASSENGER_CAR) {
      Object.assign(params, {productType: 'CAR'});
    } else if (vehicleType) {
      Object.assign(params, {productType: vehicleType});
    }
  }

  private applyBrandForPartsSearch(params: object, brand: Brand): void {
    if (brand === Brand.RR) {
      Object.assign(params, {brand: 'ROLLS_ROYCE'});
    } else if (brand === Brand.BMW_i) {
      Object.assign(params, {brand: 'BMW_I'});
    } else if (brand === Brand.MOT) {
      Object.assign(params, {brand: 'BMW'});
    } else if (brand) {
      Object.assign(params, {brand: brand});
    }
  }

  private applyTransmissionTypeForPartsSearch(params: object, transmission: TransmissionType): void {
    if (transmission === TransmissionType.AUTOMATIC_TRANSMISSION) {
      Object.assign(params, {transmissionType: 'AUTOMATIC'});
    } else if (transmission === TransmissionType.MANUAL_TRANSMISSION) {
      Object.assign(params, {transmissionType: 'MANUAL'});
    } else if (transmission === TransmissionType.UNKNOWN) {
      Object.assign(params, {transmissionType: 'NEUTRAL'});
    }
  }
}
